// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0px;
    padding: 0px;
}

.homeContainer {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    height: 100vh;
    justify-content: center;
}

@media (max-width: 576px) {
 .cardReport{
     margin-top: 3% !important;
 }
}`, "",{"version":3,"sources":["webpack://./src/screens/HomePage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;CACC;KACI,yBAAyB;CAC7B;AACD","sourcesContent":["* {\n    margin: 0px;\n    padding: 0px;\n}\n\n.homeContainer {\n    display: flex;\n    flex-direction: column;\n    margin-left: 50px;\n    height: 100vh;\n    justify-content: center;\n}\n\n@media (max-width: 576px) {\n .cardReport{\n     margin-top: 3% !important;\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
