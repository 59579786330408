import './Menu.css';
import axios from 'axios';
import {Nav} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {IoLogOutOutline} from "react-icons/io5"

const MenuLogoutButton = ({ isOpen, isMobile}) => {

    const navigate = useNavigate();

    const handleLogout = () => {
        axios.get(process.env.REACT_APP_API_LOGOUT_ROUTE, { withCredentials: true })
            .then(() => {
                navigate('/');
            })
            .catch(error => {
                console.error('Error during logout:', error);
            });
    }

  return (
      <div className={`${isMobile ? 'logoutMobile logout-section' : 'menu-item-container logout-section'}`}>
          <Nav.Link as={Link} to="/" onClick={handleLogout}>
              <div>
                  <IoLogOutOutline className="icon"/>
              </div>
              <div className='span-container'>
                  <span className={isOpen ? "text" : "text hidden"}>Logout</span>
              </div>
          </Nav.Link>
      </div>
  )
}

export default MenuLogoutButton;