import './Home.css';

const HomeTitle = () => {
    
    return (
        
        <div className='homeTitleContainer'>
            <h1 className='homeTitle'>Bienvenidos a Qreate</h1>
        </div>

    )

}

export default HomeTitle;