import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function NotFound() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
        navigate('/notFound', { replace: true, state: { from: location } });
  }, []);

  return (
    <div id="mainNotFound">
      <div className="fof">
        <h1 className='h1Error404'>Error 404</h1>
      </div>
    </div>
  );

}