// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0px;
    padding: 0px;
}

.shopContainer {
    height: 100%;
    background-color: #ffe0e0;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

@media (max-width: 768px) {

    .shopContainer {
        height: 100%;
    }

}`, "",{"version":3,"sources":["webpack://./src/screens/ShopPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;;IAEI;QACI,YAAY;IAChB;;AAEJ","sourcesContent":["* {\n    margin: 0px;\n    padding: 0px;\n}\n\n.shopContainer {\n    height: 100%;\n    background-color: #ffe0e0;\n    display: flex;\n    flex-direction: column;\n    margin-left: 50px;\n}\n\n@media (max-width: 768px) {\n\n    .shopContainer {\n        height: 100%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
