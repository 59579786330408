const HomeQRModal = ({ qr, download }) => {
  
    return (

    <div className="modal fade" id="modalQR" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title fs-5 titleModalQr" id="modalCreateCartLabel">QR!</h2>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body divQr">
                    {qr && <img src={qr} alt="QR Code" />}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={download}>Descargar</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir</button>
                </div>
            </div>
        </div>
    </div>
  )

}

export default HomeQRModal;