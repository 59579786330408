// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active>.page-link {
    background-color: rgb(235, 120, 107);
    border-color: rgb(235, 120, 107);
    color: white;
}

.page-link {
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/screens/Categories.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".active>.page-link {\n    background-color: rgb(235, 120, 107);\n    border-color: rgb(235, 120, 107);\n    color: white;\n}\n\n.page-link {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
