import React from 'react'

const HomeQRButton = ({ action, title, subtitle }) => {

  return (
    <div className='cardItem mt-3'>
        <div className="cardItemButton" onClick={action} data-bs-target="#modalQR" data-bs-toggle="modal">
            <div className='cardTextContainer'>
                <p className='cardTitle mb-0'>{title}</p>
                <p className='cardSubtitle mb-0'>{subtitle}</p>
            </div>
        </div>
    </div>
  )

}

export default HomeQRButton