import React, {useEffect, useState} from 'react'
import axios from "axios";
import {Button, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import './ReporteCategorias.css';
import {Search} from "@carbon/icons-react";

const ReporteCategorias = () => {

    const [categoryReport, setCategoryReport] = useState([]);
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [isDateFromInvalid, setIsDateFromInvalid] = useState(false);
    const [isDateToInvalid, setIsDateToInvalid] = useState(false);

    const getConfig = () => {
        return {
            withCredentials: true,
            params: {
                fromDate: dateFrom,
                toDate: dateTo
            }
        }
    }

    const getAllMostSelledCategories = () => {

        if(!dateFrom && !dateTo) {
            setIsDateFromInvalid(true);
            setIsDateToInvalid(true);
        } else {
            axios.get(process.env.REACT_APP_API_REPORTE_CATEGORIAS_ROUTE, getConfig())
                .then((response) => {
                    setCategoryReport(response.data.reportMostSelledCategoriesDetailsDTO);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'dateFrom') {
            setDateFrom(value);
            setIsDateFromInvalid(false);
        } else if (name === 'dateTo') {
            setDateTo(value);
            setIsDateToInvalid(false);
        }
    };

    return (
        <div className="reporteCategoriasContainer">
            <Container className="mt-3 mb-3">
                <Row>
                    <Col>
                        <h1 className="titleReporteCategorias">Reporte de categorías mas vendidas</h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={5}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Fecha Desde</InputGroup.Text>
                            <Form.Control
                                aria-label="dateFrom"
                                type="date"
                                name="dateFrom"
                                value={dateFrom}
                                onChange={handleDateChange}
                                isInvalid={isDateFromInvalid}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={5}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Fecha Hasta</InputGroup.Text>
                            <Form.Control
                                aria-label="dateTo"
                                type="date"
                                name="dateTo"
                                value={dateTo}
                                onChange={handleDateChange}
                                isInvalid={isDateToInvalid}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={1}>
                        <Button className="me-1" variant="primary" onClick={() => getAllMostSelledCategories()}>
                            <Search/>
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <Table responsive className="table-striped">
                            <thead>
                            <tr>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Ranking</th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>ID de categoría</th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Nombre de categoría</th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Cantidad vendida</th>
                            </tr>
                            </thead>
                            <tbody>
                            {   categoryReport.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center">Por favor ingrese un filtro</td>
                                </tr>
                            ) : (
                                categoryReport.map((category, index) => (
                                    <tr key={category.categoryId}>
                                        <td>{index + 1}</td>
                                        <td>{category.categoryId}</td>
                                        <td>{category.categoryName}</td>
                                        <td>{category.quantity}</td>
                                    </tr>
                                )))
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default ReporteCategorias;