const HomeNoShop = () => {
  
    return (
        <div className="homeNoShopContainer">
            <h3 className='homeNoShopText'>Una vez completados los datos de tu local podrás acceder a la carta y obtener el QR para descargar.</h3>
        </div>
    );

}

export default HomeNoShop;