import React, {useEffect, useState} from 'react';
import Loading from '../components/Loading';
import './ShopPage.css';
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {getShop, updateShop} from "../services/ShopService";
import Swal from "sweetalert2";

export default function ShopPage() {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [shopDTO, setShopDTO] = useState({
        id: null,
        name: '',
        address: '',
        phone: '',
        state: '',
        location: '',
        businessName: '',
        pathLogo: null,
        pathQr: null,
        principalColorShop: '',
        image: null,
        imageFormat: null,
        modules: [],
        uuid: ''
    })
    const [editShopDTO, setEditShopDTO] = useState({
        id: null,
        name: '',
        address: '',
        phone: '',
        state: '',
        location: '',
        businessName: '',
        pathLogo: null,
        pathQr: null,
        principalColorShop: '',
        image: null,
        imageFormat: null,
        modules: [],
        uuid: ''
    })

    useEffect(() => {

        const getShopDTO = async() => {
            try {
                return await getShop(shopDTO);
            } catch (error) {
                throw error;
            }
        }

        getShopDTO()
            .then((data) => {
                setShopDTO(data);
                setEditShopDTO(data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message,
                })
            })
            .finally(setLoading(false));

    }, [])

    const handleCloseModal = () => {
        setShow(false);
        setTimeout(() => {
            setErrors({})
        }, 200);
    };
    const handleShowModal = () => {
        setEditShopDTO(shopDTO);
        setShow(true);
    }
    const handleValidation = () => {
        let tempErrors = {};
        let isValid = true;

        if (!editShopDTO.name || editShopDTO.name.trim() === "") {
            isValid = false;
            tempErrors["name"] = "El nombre de la tienda es requerido";
        }

        if (!editShopDTO.businessName || editShopDTO.businessName.trim() === "") {
            isValid = false;
            tempErrors["businessName"] = "La razón social es requerida";
        }

        if (!editShopDTO.phone || editShopDTO.phone.trim() === "") {
            isValid = false;
            tempErrors["phone"] = "El teléfono es requerido";
        }

        if (!editShopDTO.address || editShopDTO.address.trim() === "") {
            isValid = false;
            tempErrors["address"] = "La dirección es requerida";
        }

        if (!editShopDTO.state || editShopDTO.state.trim() === "") {
            isValid = false;
            tempErrors["state"] = "La localidad es requerida";
        }

        if (!editShopDTO.location || editShopDTO.location.trim() === "") {
            isValid = false;
            tempErrors["location"] = "La provincia es requerida";
        }

        setErrors(tempErrors);
        return isValid;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            updateShop(editShopDTO)
                .then((data) => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Datos actualizados',
                    })
                    setShopDTO(data)
                    setEditShopDTO(data)
                    handleCloseModal()
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.message,
                    })
                })
        }
    };

    return (
        <>
            {loading ? (
                <Loading/>
            ) : (
                <div style={{marginLeft: '50px'}}>
                    <Container className='mt-3'>
                        <Row>
                            <Col xs={12}>
                                <h1 className="titleShop">Local</h1>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="mt-3">
                        <Form>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formShopName">
                                        <Form.Label>Nombre de tienda</Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese nombre de tienda" value={shopDTO.name} disabled/>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formShopName">
                                        <Form.Label>Razon Social</Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese nombre de tienda" value={shopDTO.businessName} disabled/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formShopName">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese nombre de tienda" value={shopDTO.phone} disabled/>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formShopName">
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese nombre de tienda" value={shopDTO.address} disabled/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formShopName">
                                        <Form.Label>Localidad</Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese nombre de tienda" value={shopDTO.state} disabled/>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-3" controlId="formShopName">
                                        <Form.Label>Provincia</Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese nombre de tienda" value={shopDTO.location} disabled/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={{span: 2, offset: 10}} className="d-flex justify-content-end">
                                    <Button variant="primary" onClick={handleShowModal}>
                                        Editar datos
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>

            )}

            <Modal show={show} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar datos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formShopName">
                                    <Form.Label>Nombre de tienda</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese nombre de tienda"
                                        value={editShopDTO.name}
                                        onChange={(e) =>
                                            setEditShopDTO({
                                                ...editShopDTO,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.name && <span className="text-danger">{errors.name}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formBusinessName">
                                    <Form.Label>Razón Social</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese nombre de tienda"
                                        value={editShopDTO.businessName}
                                        onChange={(e) =>
                                            setEditShopDTO({
                                                ...editShopDTO,
                                                businessName: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.businessName && <span className="text-danger">{errors.businessName}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formPhone">
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese teléfono"
                                        value={editShopDTO.phone}
                                        onChange={(e) =>
                                            setEditShopDTO({
                                                ...editShopDTO,
                                                phone: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formAddress">
                                    <Form.Label>Dirección</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese dirección"
                                        value={editShopDTO.address}
                                        onChange={(e) =>
                                            setEditShopDTO({
                                                ...editShopDTO,
                                                address: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.address && <span className="text-danger">{errors.address}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formState">
                                    <Form.Label>Localidad</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese localidad"
                                        value={editShopDTO.state}
                                        onChange={(e) =>
                                            setEditShopDTO({
                                                ...editShopDTO,
                                                state: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.state && <span className="text-danger">{errors.state}</span>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formLocation">
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese provincia"
                                        value={editShopDTO.location}
                                        onChange={(e) =>
                                            setEditShopDTO({
                                                ...editShopDTO,
                                                location: e.target.value,
                                            })
                                        }
                                    />
                                    {errors.location && <span className="text-danger">{errors.location}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Cerrar</Button>
                    <Button variant="primary" onClick={handleSubmit}>Guardar datos</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}