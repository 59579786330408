import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {TrashCan} from '@carbon/icons-react';

export default function SocialMedia(){

    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");

    const [isUpdateImage, setIsUpdateImage] = useState(null);
    const [imagesBanners, setImagesBanners] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    // Imagen de logo de carta
    const [selectedLogoImage, setSelectedLogoImage] = useState(null);
    const [selectedLogoImagePreview, setSelectedLogoImagePreview] = useState(null);

    // Color de categorías de carta
    const [categoryColor, setCategoryColor] = useState(null);

    const [idBanner, setIdBanner] = useState(null);

    const [showModalEditImageBanner, setShowModalEditImageBanner] = useState(false);

    useEffect(() => {

        document.title = 'QReate - Diseño';

        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                status: true,
            }
        })
        .then(response => {
            getValueRedInstagram(response.data);
            getValueRedFacebook(response.data);
            getValueRedTwitter(response.data);
        })

        getAllImagesBanners();
        getCategoryColor();

        // Obtener Logo de catálogo Carta
        axios.get(process.env.REACT_APP_API_SHOP_ROUTE_UPDATE_LOGO_IMAGE + "/1", {
            withCredentials: true,
            responseType: "blob",
        })
            .then((response) => {
                setSelectedLogoImage(response.data)
                const imageBlob = response.data;
                const imageURL = URL.createObjectURL(imageBlob);
                setSelectedLogoImage(imageBlob);
                setSelectedLogoImagePreview(imageURL);
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error cargando la imagen del logo',
                })
            })

    }, []);

    const handleLogoImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedLogoImage(e.target.files[0]);
            setSelectedLogoImagePreview(URL.createObjectURL(e.target.files[0]))
        }
    };

    const getAllImagesBanners = () => {
        axios.get(process.env.REACT_APP_API_BANNER_IMAGES_GET_ROUTE, {
            withCredentials: true,
        }).then(response => {
            setImagesBanners(response.data);
        })
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImage(file);
        }
    };

    const handleImageUpload = () => {
        setIsUpdateImage(true);
        // Validar si se ha seleccionado una imagen
        if (!selectedImage) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se ha seleccionado ninguna imagen.',
            });
            setIsUpdateImage(false);
            return;
        }

        // Validar el tamaño de la imagen (máximo 1MB)
        const maxFileSize = 1 * 1024 * 1024; // 1MB en bytes
        if (selectedImage.size > maxFileSize) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El archivo excede el tamaño máximo permitido de 1MB.',
            });
            setIsUpdateImage(false);
            return;
        }

        const formData = new FormData();
        formData.append('idBanner', idBanner);
        formData.append('image', selectedImage);

        axios.post(`${process.env.REACT_APP_API_BANNER_IMAGES_POST_ROUTE}`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Imagen cargada correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalEditImageBanner(false);
                getAllImagesBanners();
            })
            .catch(error => {
                const errorMessage = error.response?.data || 'Hubo un problema al cargar la imagen.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });
                console.error(error);
            }).finally(() => {
            setIsUpdateImage(false);
        });
    };

    const deleteImage = (idBanner) => {
        axios.delete(process.env.REACT_APP_API_BANNER_IMAGES_DELETE_ROUTE + idBanner.toString(), {
            responseType: 'arraybuffer',
            withCredentials: true
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Imagen eliminada.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalEditImageBanner(false);
                getAllImagesBanners();
            })
            .catch(error => {
                alertError();
                console.error(error);
            });
    }

    const getValueRedInstagram = (redes) =>{
        let instagram = redes && redes.find(red => red.socialMediaType === "INSTAGRAM");
        if(instagram != null){
            setInstagram(instagram.socialMediaLink);
        }
    }

    const getValueRedFacebook = (redes) =>{
        let facebook = redes && redes.find(red => red.socialMediaType === "FACEBOOK");
        if(facebook != null){
            setFacebook(facebook.socialMediaLink);
        }
    }

    const getValueRedTwitter = (redes) =>{
        let twitter = redes && redes.find(red => red.socialMediaType === "TWITTER");
        if(twitter != null){
            setTwitter(twitter.socialMediaLink);
        }
    }

    function alertError(){
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    const saveInstagram = () =>{
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                socialMediaType: "INSTAGRAM",
            }
        })
        .then(response => {
            if(response.data[0] != null){
                let redSocial = {
                    socialMediaType: "INSTAGRAM",
                    socialMediaLink: instagram,
                    status: true
                }
    
                axios.put(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE + '/' + response.data[0].id, redSocial, {
                    withCredentials: true
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }else{
                let newRedSocial = {
                    socialMediaType: "INSTAGRAM",
                    socialMediaLink: instagram
                }
                axios.post(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, newRedSocial, {
                    withCredentials: true
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }
        }) 
    }

    const saveFacebook = () =>{
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                socialMediaType: "FACEBOOK",
            }
        })
        .then(response => {
            if(response.data[0] != null){
                let redSocial = {
                    socialMediaType: "FACEBOOK",
                    socialMediaLink: facebook,
                    status: response.data[0].status
                }
    
                axios.put(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE + '/' + response.data[0].id, redSocial,{
                    withCredentials: true
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }else{
                let newRedSocial = {
                    socialMediaType: "FACEBOOK",
                    socialMediaLink: facebook
                }
                axios.post(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, newRedSocial, {
                    withCredentials: true
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }
        }) 
    }

    const saveTwitter = () =>{
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                socialMediaType: "TWITTER",
            }
        })
        .then(response => {
            if(response.data[0] != null){
                let redSocial = {
                    socialMediaType: "TWITTER",
                    socialMediaLink: twitter,
                    status: response.data[0].status
                }
    
                axios.put(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE + '/' + response.data[0].id, redSocial, {
                    withCredentials: true
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }else{
                let newRedSocial = {
                    socialMediaType: "TWITTER",
                    socialMediaLink: twitter
                }
                axios.post(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, newRedSocial, {
                    withCredentials: true
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }
        }) 
    }

    const getUrlBannerById = (idBanner) => {
        if (!imagesBanners || imagesBanners.length === 0) return null; // Verificación para evitar errores

        const banner = imagesBanners.find(b => b.id === idBanner);
        return banner ? banner.urlImage : null; // Retorna la URL si se encuentra el banner, de lo contrario retorna null
    };

    const saveLogoImage = () => {
        if (selectedLogoImage) {
            const formData = new FormData();
            formData.append('logoImage', selectedLogoImage);

            axios.post(process.env.REACT_APP_API_SHOP_ROUTE_UPDATE_LOGO_IMAGE + "/1", formData, {
                withCredentials: true
            })
                .then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Éxito',
                        text: 'La imagen se ha guardado con éxito'
                    })
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.headers['x-error-message']
                    })
                });
        }
    }

    const getCategoryColor = () => {
        axios.get(process.env.REACT_APP_API_SHOP_ROUTE_UPDATE_CATEGORY_COLOR + "/1", {
            withCredentials: true
        })
            .then((response) => {
                setCategoryColor(response.data);
            })
            .catch((error) => {console.log(error)})
    }

    const handleCategoryColorSave = () => {
        axios.post(process.env.REACT_APP_API_SHOP_ROUTE_UPDATE_CATEGORY_COLOR + "/1", categoryColor,
            {
                withCredentials: true,
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Color cargado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
            })
            .catch(console.error);
    }

    return(
    <div>
        <div>

                <Container className='mt-3'>
                    <Row>
                        <Col>
                            <h1 className="titleDiseño">Diseño y personalización</h1>
                        </Col>
                    </Row>
                </Container>

                <Container className='mt-3' style={{ border: '2px solid #aaa', padding: '20px', borderRadius: '5px' }}>
                    <Row>
                        <Col>
                            <h2 className='titleCatalogStyle'>Estilo de catálago</h2>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <h3 style={{color: 'rgb(255, 157, 146)', fontFamily: 'Poppins, sans-serif'}}>Logo de catálogo</h3>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Form.Group className='mb-3' controlId="formImage">
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleLogoImageChange}
                            />
                            {/* Vista previa de la imagen seleccionada */}
                            {selectedLogoImage && (
                                <img
                                    className='mt-3'
                                    src={selectedLogoImagePreview}
                                    alt="Vista previa"
                                    style={{
                                        backgroundColor: '#ccc',
                                        marginTop: '10px',
                                        maxWidth: '200px',
                                        height: 'auto',
                                        border: '2px solid #bbb', // Añadir un borde gris
                                        padding: '5px', // Añadir un poco de espacio interior
                                        borderRadius: '5px' // Opcional: bordes redondeados
                                    }}
                                />
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={saveLogoImage}>Guardar imagen</Button>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col>
                            <h3 style={{color: 'rgb(255, 157, 146)', fontFamily: 'Poppins, sans-serif'}}>Color de categorías</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formColorPicker">
                                <Form.Label style={{color: 'rgb(255, 157, 146)', fontFamily: 'Poppins, sans-serif'}}>Selecciona un color</Form.Label>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Form.Control
                                        type="color"
                                        value={categoryColor}
                                        onChange={(event) => {
                                            setCategoryColor(event.target.value)
                                        }}
                                        title="Elige un color"
                                        aria-label="Elige un color"
                                        className='me-2'
                                    />
                                    <Form.Control
                                        type="text"
                                        value={categoryColor}
                                        title="Color elegido"
                                        aria-label="Color elegido"
                                        disabled
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Button onClick={handleCategoryColorSave}>Guardar color</Button>
                        </Col>
                    </Row>
                </Container>

                <Container className='mt-3' style={{ border: '2px solid #aaa', padding: '20px', borderRadius: '5px' }}>
                    <h3 className='titleCatalogStyle'>Banners publicitarios</h3>
                    <br/>
                    <Row className="g-0"> {/* Añade g-0 para eliminar el espacio entre columnas */}
                        {Array.from({ length: 5 }).map((_, index) => (
                            <Col xs={12} sm={6} md={4} lg={4} className="mb-3" key={index}>
                                <div className="banner-item">
                                    <p className='labelsRedes'>{`Banner ${index + 1}:`}</p>
                                    {getUrlBannerById(index + 1) != null ?
                                        <>
                                            <img src={getUrlBannerById(index + 1)} alt="Imagen del producto"
                                                 className="img-fluid"
                                                 width={200}/>
                                            <br/>
                                            <br/>
                                            <Button
                                                id="btnSaveImageBanner"
                                                variant="primary"
                                                className="btnSaveImageBanner btnSaveRedes"
                                                onClick={() => {
                                                    setShowModalEditImageBanner(true);
                                                    setIdBanner(index + 1);
                                                }}
                                            >
                                                Editar imagen
                                            </Button>
                                            <button className="btn btnDeleteBanner" style={{marginLeft:'3%'}} onClick={() => {deleteImage(index + 1);}} disabled={isUpdateImage}>
                                                <TrashCan className="btnDelete" size="24"/>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <Button
                                                id="btnSaveImageBanner"
                                                variant="primary"
                                                className="btnSaveImageBanner btnSaveRedes"
                                                onClick={() => {
                                                    setShowModalEditImageBanner(true);
                                                    setIdBanner(index + 1);
                                                }}
                                            >
                                                Subir imagen
                                            </Button>
                                        </>
                                    }
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>

                <Container className='mt-3' style={{ border: '2px solid #aaa', padding: '20px', borderRadius: '5px' }}>

                    <h3 className='titleCatalogStyle'>Redes sociales</h3>
                    <br/>

                    <div class="mb-3">
                        <label for="nombre" class="col-form-label labelsRedes">Instagram</label>
                        <input placeholder="Link del perfil de instagram" type="text" class="form-control" id="nombre"
                               value={instagram} onChange={(e) => {
                            const instagramIng = e.target.value;
                            if(instagramIng.length <= 255){
                                setInstagram(instagramIng);
                            }}}/>
                    </div>

                    <div className="divSaveRedes">
                        <button className="btnSaveRedes btn" style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}} onClick={() => {
                            if(instagram != null){
                                saveInstagram();
                            }}}>Guardar</button>
                    </div>

                    <br/>

                    <div class="mb-3">
                        <label for="nombre" class="col-form-label labelsRedes">Facebook</label>
                        <input placeholder="Link del perfil de facebook" type="text" class="form-control" id="nombre" value={facebook} onChange={(e) => {
                            const facebookIng = e.target.value;
                            if(facebookIng.length <= 255){
                                setFacebook(facebookIng);
                            }}}/>
                    </div>

                    <div className="divSaveRedes">
                        <button className="btnSaveRedes btn" style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}} onClick={() => {
                            if(facebook != null){
                                saveFacebook();
                            }}}>Guardar</button>
                    </div>

                    <br/>

                    <div class="mb-3">
                        <label for="nombre" class="col-form-label labelsRedes">Twitter</label>
                        <input placeholder="Link del perfil de twitter" type="text" class="form-control" id="nombre" value={twitter} onChange={(e) => {
                            const twitterIng = e.target.value;
                            if(twitterIng.length <= 255){
                                setTwitter(twitterIng);
                            }}}/>
                    </div>

                    <div className="divSaveRedes">
                        <button className="btnSaveRedes btn" style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}} onClick={() => {
                            if(twitter != null){
                                saveTwitter();
                            }}}>Guardar</button>
                    </div>
                </Container>
            </div>
        <Modal size="xl" show={showModalEditImageBanner} onHide={() => setShowModalEditImageBanner(false)}>
            <Modal.Header className="modal-header modalImageProduct">
                <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                    className="titleModalEditProduct">Editar imagen del banner {idBanner}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                    setShowModalEditImageBanner(false);
                }}></button>
            </Modal.Header>

            <Modal.Body className="modal-body bodyModalImageProduct">
                <p className='recomendacionImg'>Tamaño recomendado: 530 x 230 px</p>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                    className="form-control mt-3"
                />
            </Modal.Body>

            <Modal.Footer className="modal-footer modalImageProduct">
                <button id="btnCloseModalImageProduct" type="button" className="btn btn-secondary"
                        data-bs-dismiss="modal" onClick={() => {
                    setShowModalEditImageBanner(false);
                }}>Cerrar
                </button>
                <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary" onClick={() => {
                    handleImageUpload();
                }}
                        disabled={isUpdateImage}>Cargar imagen
                </button>
            </Modal.Footer>
        </Modal>
    </div>
    );
}