export const downloadFile = (data, filename, format) => {

    // Creación del link de descarga
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename + "." + format);
    document.body.appendChild(link);
    link.click();

    // Limpia los objetos utilizados para la descarga
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);

}