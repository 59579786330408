import axios from 'axios';

const getConfig = () => ({
    withCredentials: true
});

export const getWithCredentialsSync = (url) => {
    return axios.get(url, getConfig());
}

export const postWithCredentialsSync = (url, data) => {
    return axios.post(url, data, getConfig());
}
export const putWithCredentialsSync = (url, data) => {
    return axios.put(url, data, getConfig());
}

export const postWithCredentials = async(url, data) => {
    return await axios.post(url, data, getConfig());
};

